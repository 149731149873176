@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F7FA !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.rbt-input {
  display: 'block';
  width: 100%;
  padding: 10px;
  padding: 10px;
  box-sizing: 'border-box';
  border-radius: 4px;
  border: 1px solid #c1c7d0;
  color: #5E6C84;
  transition: 0.3s;
  background: #fff;
}

.rbt-input:focus {
  outline: none;
  border: 1px solid #0050c8;
  color: #5E6C84;
  background-color: #fff;
}

.rdw-editor-wrapper {
  background-color: #fff !important;
}

.bg-success{
  background-color: #d6ffdf !important;
}

.dropdown-toggle::after {}

.dropdown-menu.show {
  width: 100%;
  padding-left: 10px;
  font-size: 15px;
}

.dropdown-menu.show .form-check {
  padding-top: 12px;
}

#dropdown-with-checkbox {
  border: 1px solid #CED4DA;
  width: 100%;
  text-align: left;
  background-color: #F4F5F7;
  color: #5E6C84;
}

.modal-dialog {
  max-width: 500px !important;
  margin: 20vh auto !important;
}
.wide-modal > .modal-dialog{
  max-width: 80vw !important;
  margin: 20vh auto !important;
}

@media screen and (max-width:997px){
  html, body {
  overflow-x: hidden
  }
}
