@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&display=swap');

@import './variables.scss';

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-VariableFont_slnt\,wght.ttf');
}

.InputElement {
  height: 45px;
  padding: 15px 10px;
  background: #f4f5f7;
  border: 1px solid #c1c7d0;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
}

body {
  background: #e5e5e5;
}


.Invalid {
  border: 1px solid #f00 !important;
}

.rdw-editor-wrapper {
  background-color: #f9f9f9;
  padding: 5px;
  width: 500px;

  .rdw-editor-main {
    height: 180px;
    font-size: 15px;
  }
}
.hide-field {
  display: none;
}
.show-field {
  display: block !important;
}
.remove-overflow {
  height: auto !important;
  overflow-y: unset !important;
  display: block !important;
}
.show-item {
  display: block !important;
}

.color-pending {
  background: #ffecd6;
  border-radius: 4px;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #e88612 !important;
  flex: none !important;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px !important;
  display: inline-block;
  padding: 5px 15px;
}

.color-approved {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #15a412 !important;
  flex: none !important;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px !important;
  background: #dcffd6;
  border-radius: 4px;
  display: inline-block;
 padding: 5px 15px 5px !important
}

.color-nofile {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #666666 !important;
  flex: none !important;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px !important;
  background: #ececec;
  border-radius: 4px;
  display: inline-block;
  padding: 5px 15px 5px !important
}

.color-rejected {
  background: #eb5757;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #fff !important;
  flex: none !important;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px !important;
  border-radius: 4px;
  display: inline-block;
   padding: 5px 15px 5px !important
}

.color-pending{
  background: #D6D8FF;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #1A21BA !important;
  flex: none !important;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px !important;
  border-radius: 4px;
  display: inline-block;
   padding: 5px 15px 5px !important
}

.color-decline{
  background: #FFDBD6;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #EB5757 !important;
  flex: none !important;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px !important;
  border-radius: 4px;
  display: inline-block;
   padding: 5px 15px 5px !important
}


.color-acknowledge{
background: #D6FFDF;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
color: #10920D !important;
  flex: none !important;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px !important;
  border-radius: 4px;
  display: inline-block;
   padding: 5px 15px 5px !important
}