

.test .Toastify__toast--success {
    border: 1px dashed #1F691D;
}

.test .Toastify__toast-body {
       text-align: left;
       /* background: rgba(21, 164, 18, 0.2); */
       color: #1F691D;
     }

     .Toastify__toast--success {
        background: #D6FFDF !important;
        box-shadow: none;
      }

      .Toastify__toast--success .Toastify__close-button:after {
        content: 'dismiss';
        color: #1F691D;
        font-size: 14px;
        font-weight: bold;
      }

      .Toastify__close-button svg {
        visibility: hidden;
      }

      .Toastify__toast--error .Toastify__close-button:after {
        content: 'dismiss';
        color: #FFF;
        font-size: 14px;
        font-weight: bold;
      }

      .Toastify__close-button {
        margin: auto 0;
        outline: none !important;
        /* display: none; */
      }

      .Toastify__toast {
        min-height: 34px !important;
        font-size: 14px;
        min-width: 432px;
        padding: 5px 15px !important;
        box-shadow: none;
        border-style: dotted;
      }

      .Toastify__toast--error .Toastify__close-button:after {
        content: 'dismiss';
        color: #FFF;
        font-size: 14px;
        font-weight: bold;
      }
      
      .Toastify__toast--success {
        background: #D6FFDF !important;
        box-shadow: none;
      }
      .Toastify__toast--error{
        border: 1px dashed rgb(238, 133, 133);
      }

      .Toastify__toast--error .Toastify__toast-body {
        text-align: left;
        /* background: rgba(21, 164, 18, 0.2); */
        color: #FFF;
      }
      .Toastify__progress-bar {
        visibility: hidden;
      }
      